<template>
  <div style="margin-top: 10px">
    <div
      style="
        padding-bottom: 10px;
        border-bottom: #218da0 1px solid;
        margin-bottom: 20px;
      "
    >
      <el-button type="danger" style="margin-left: 20px" @click="sysGoodsType"
        >同步</el-button
      >
    </div>

    <div class="goodsType">
      <el-tree
        :data="data"
        show-checkbox
        node-key="id"
        :default-expand-all="false"
        :expand-on-click-node="false"
        :render-content="renderContent"
        class="treeDIV"
      >
      </el-tree>
    </div>
  </div>
</template>

<script>
// let id = 1000;

export default {
  data() {
    return {
      data: [],
    };
  },
  created() {
    //获取类别
    this.getClassData();
  },
  methods: {
    //同步商品
    sysGoodsType() {
      this.$confirm("确定同步海鼎数据？").then((_) => {

        let loadingInstance1=this.$loading({ fullscreen: true, text: "数据正在同步..." });

        this.$axios.post("/a1/gt/syGoodsType").then((res) => {
          this.$message.success("同步成功");
          this.getClassData();

          loadingInstance1.close()

        });
      });
    },
    //获取类别
    getClassData() {
      this.$axios.get("/a1/gt/tree").then((res) => {
        this.data = res.data.data;
      });
    },

    append() {
      this.$message.error("编辑");
      // const newChild = { id: id++, label: 'testtest', children: [] };
      // if (!data.children) {
      //   this.$set(data, 'children', []);
      // }
      // data.children.push(newChild);
    },

    remove(node, data) {
      this.$message.error("删除");
      // const parent = node.parent;
      // const children = parent.data.children || parent.data;
      // const index = children.findIndex(d => d.id === data.id);
      // children.splice(index, 1);
    },
    renderContent(h, { node, data, store }) {
      return (
        <span class="custom-tree-node">
          <span>
            {data.cls_no} > {data.cls_cn} : {data.cls_name}
          </span>

          <span>
            <el-button
              size="mini"
              type="text"
              on-click={() => this.append(data)}
            >
              编辑
            </el-button>
            <el-button
              size="mini"
              type="text"
              on-click={() => this.remove(node, data)}
            >
              删除
            </el-button>
          </span>
        </span>
      );
    },
  },
};
</script>

<style>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.el-tree-node__content {
  border-bottom: #889aa4 1px solid;
  height: 30px;
}

/* 修改原本样式 */
.goodsType {
  width: 98%;
  margin: 0 auto;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #218da0;
}

.treeDIV {
  border-radius: 20px;
}

.el-tree-node__content {
  height: 35px;
}
</style>
